.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.delete-button {
  color: red;
}

.delete-button-disabled {
  color: gray;
}

.Approved-color {
  color: green;
}

.UnApproved-color {
  color: red
}

.Pending-color {
  color: gray
}

.Expired-color {
  color: gray
}

.download-button {
  color: #077398;
}
.audit-download-list {
  overflow-x:hidden;
  overflow-y:auto;
  height:100px;
  border: solid 1px;
  margin-bottom: 10px;
}

.pending {
  color: gray
}

.pass {
  color: green
}

.fail {
  color: red
}

.inline-text {
  display: inline;
}

.nowrap-text {
  text-wrap: nowrap;
  display: flex;
  align-items: center;
}




